import React, { useState, useEffect } from 'react'
import Login from './routes/login/Login'
import Logout from './routes/logout/Logout'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { theme, ThemeProvider } from '@papertrail/styleguide'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Signup from './routes/signup/Signup'
import ResetPassword from './routes/forgot-password/ResetPassword'
import RespondResetPassword from './routes/forgot-password/ResetPasswordRespond'
import InlineFrame from './components/InlineFrame'
import './App.css'
import OAuthRedirect from './routes/oauth-redirect/OAuthRedirect'
import { Partner } from '@papertrail/types'
import { PapertrailSession } from '@papertrail/web3-session'

// These props are supplied by the single-spa framework
type Props = {
  getPrevRoute?: () => string
  recaptchaV3SiteKey?: string
  [key: string]: any
}

export default function App(props: Props) {
  const [loginRedirect, setLoginRedirect] = useState('/')
  const partner: Partner = PapertrailSession.partner

  useEffect(() => {
    if (props.getPrevRoute) {
      setLoginRedirect(props.getPrevRoute())
    }
  }, [])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={props.recaptchaV3SiteKey}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className={'container-public'}>
            <Switch>
              {!partner.id && (
                <>
                  <Route
                    path="/signup"
                    render={() => <InlineFrame url="https://www.papertrail.io/onboarding-page" />}
                  />
                  <Route
                    path="/login"
                    render={() => <InlineFrame url="https://www.papertrail.io/loginpage-iframe" />}
                  />
                </>
              )}
              {partner.images.publicCompany && (
                <Route
                  render={() => (
                    <div
                      className={'container-public-left container-public-left-bg'}
                      style={{ backgroundImage: 'url(' + partner.images.publicCompany + ')', backgroundSize: 'cover' }}
                    />
                  )}
                />
              )}
              {!partner.images.publicCompany && (
                <Route render={() => <div className={'container-public-left container-public-left-bg'} />} />
              )}
            </Switch>

            <div className={'container-public-main'}>
              <Switch>
                <Route
                  path="/login/reset/respond"
                  render={() => <RespondResetPassword locationSearch={location.search} />}
                />
                <Route path="/oauth/redirect" render={() => <OAuthRedirect locationSearch={location.search} />} />
                <Route path="/login/reset" component={ResetPassword} />
                <Route path="/login" render={() => <Login prevRoute={loginRedirect} />} />
                <Route path="/signup" render={() => <Signup prevRoute={loginRedirect} />} />
                <Route
                  path="/logout"
                  render={() => {
                    setLoginRedirect('/')
                    return <Logout />
                  }}
                />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}
