import React from 'react'

type Props = {
  items: {
    name: string
    url: string
    image: string
  }[]
}

function SSOProviderList(props: Props) {
  const images = props.items.map((provider) => (
    <li key={provider.name} style={{ listStyle: 'none' }}>
      <a href={provider.url}>
        <img src={provider.image} alt={`${provider.name}`} />
      </a>
    </li>
  ))

  return <ul style={{ textAlign: 'center', padding: 0 }}>{images}</ul>
}

export default SSOProviderList
