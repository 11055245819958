import React from 'react'
import './InlineFrame.css'

type Props = {
  url: string
}

export default function InlineFrame(props: Props) {
  const { url } = props
  return (
    <div className="container-public-left iframe-container">
      <iframe scrolling="no" allowTransparency={true} src={url} className="left-iframe" />
    </div>
  )
}
