import { useApiGet, useApiPost } from '@papertrail/web3-utils'

export const useLoginApi = () => {
  const [loginState, postLogin] = useApiPost('/auth/access_token', (data) => data)

  function doLogin(username, password, rememberMe) {
    postLogin({
      username,
      password,
      remember: rememberMe,
      grant_type: 'password'
    })
  }

  return [loginState, doLogin] as const
}

function mapSSOProviders(data) {
  return data.data.map((provider) => ({
    image: provider.image,
    name: provider.name,
    url: provider.url
  }))
}

export const useSSOProvidersApi = () => {
  return useApiGet<any>('/oauth/url', mapSSOProviders)
}
