import { ApiRequest, requestJson } from '@papertrail/web3-utils'

export async function postLogout() {
  const fetchParams: ApiRequest = {
    method: 'POST',
    path: '/auth/logout'
  }

  return requestJson(fetchParams)
}
