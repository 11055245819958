import { useApiPost } from '@papertrail/web3-utils'

export const useLoginViaSSOApi = () => {
  const [loginState, postLogin] = useApiPost('/auth/access_token', (data) => data)

  function doLogin(code: string, invitationId?: string) {
    const loginData = {
      authorisation_code: code,
      remember: false,
      grant_type: 'sso'
    }

    if (invitationId) {
      loginData['invitation_id'] = invitationId
    }

    postLogin(loginData)
  }

  return [loginState, doLogin] as const
}
