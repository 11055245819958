import { requestJson, useApiGet, useApiPost, sleep } from '@papertrail/web3-utils'
import type { Data, SignupData, SignupState } from './types'
import { useEffect, useState } from 'react'
import { SelectOption } from '@papertrail/styleguide'
import { useLoginApi } from '../login/hooks'

function mapCountries(data): SelectOption[] {
  const countries = data.data.map((c) => ({
    value: c.alpha_2,
    label: c.name
  }))

  return countries
}

function mapIndustries(data): SelectOption[] {
  const industries = data.data.map((c) => ({
    value: c.id,
    label: c.name
  }))

  // API will only order alphabetically, so this moves 'Other' to the end.
  const otherIndex = industries.findIndex((x) => x.label === 'Other')

  if (otherIndex > -1) {
    const removed = industries.splice(otherIndex, 1)
    industries.push(removed[0])
  }

  return industries
}

export const useCountriesApi = () => {
  return useApiGet<SelectOption[]>('/countries', mapCountries)
}

export const useIndustriesApi = () => {
  return useApiGet<SelectOption[]>('/industries', mapIndustries)
}

const initialSignupState: SignupState = {
  isLoading: false,
  isLoaded: false,
  isWaitingForAccount: false,
  isError: false,
  accountId: undefined,
  error: undefined
}

export const useSignupApi = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [signupState, setSignupState] = useState<SignupState>(initialSignupState)
  const [signup, postSignup] = useApiPost<Data>('/signup', (data): Data => data.data)
  const [loginState, doLogin] = useLoginApi()

  function doSignup(signupData: SignupData) {
    setSignupState({ ...initialSignupState, isLoading: true })
    const payload: any = {
      account_name: `${signupData.firstName}'s Account`,
      company_name: signupData.companyName,
      country_code: signupData.countryCode,
      demo: false,
      email: signupData.email,
      name: `${signupData.companyName}'s Subscription`,
      phone_number: signupData.phoneNumber,
      industry_id: signupData.industry,
      'g-recaptcha-response': signupData.reCaptchaCode,
      first_name: signupData.firstName,
      last_name: signupData.lastName,
      password: signupData.password,
      password_confirmation: signupData.confirmPassword,
      terms: true,
      with_data: true
    }
    setUsername(signupData.email)
    setPassword(signupData.password)
    postSignup(payload)
  }

  useEffect(() => {
    if (signup.isLoaded) {
      setSignupState({ ...initialSignupState, isLoading: true, isWaitingForAccount: true })
      doLogin(username, password, false)
    } else if (signup.isError) {
      setSignupState({ ...initialSignupState, isError: true, error: signup.error })
    }
  }, [signup])

  useEffect(() => {
    if (loginState.isLoaded) {
      const pollForAccount = async () => {
        try {
          const subscriptionData = await requestJson({
            path: `/subscriptions/${signup.data.id}`,
            method: 'GET',
            query: { include: 'accounts' }
          })

          const accounts = subscriptionData.data.accounts.data
          const accountId = accounts[0].id

          while (true) {
            const accountResponse = await requestJson({ path: `/accounts/${accountId}`, method: 'GET' })
            if (accountResponse.data.available) {
              setSignupState({ ...initialSignupState, isLoaded: true, accountId })
              break
            }

            await sleep(200)
          }
        } catch (e) {
          setSignupState({ ...initialSignupState, isError: true, error: e })
        }
      }

      pollForAccount()
    } else if (loginState.isError) {
      setSignupState({ ...initialSignupState, isError: true, error: loginState.error })
    }
  }, [loginState])

  return [signupState, doSignup] as const
}
