function createHeaders() {
  const headers: { [key: string]: string } = {
    Accept: 'application/vnd.papertrail.api+json; version=1.0',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  }

  return headers
}

export async function postResetPassword(email: string) {
  const payload: any = {
    email
  }

  const args: RequestInit = {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(payload)
  }

  const response = await fetch(new Request('/api/reminders', args))

  if (response.ok) {
    return await response.json()
  }
}
