import { useEffect } from 'react'
import * as api from './api'
import { useHistory } from 'react-router-dom'
import { PapertrailSession } from '@papertrail/web3-session'

function Logout() {
  const history = useHistory()

  useEffect(() => {
    api.postLogout().finally(() => {
      PapertrailSession.reset()
      history.push('/login')
    })
  })

  return null
}

export default Logout
