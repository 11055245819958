import { useApiPost } from '@papertrail/web3-utils'

type ResetParams = {
  email: string
  code: string
  password: string
  confirmPassword: string
}

export function useResetPasswordApi() {
  const [resetPasswordState, postResetPassword] = useApiPost('/reminders/actions/reset', (data) => data)

  function doPostResetPassword(values: ResetParams) {
    const postData = {
      email: values.email,
      code: values.code,
      password: values.password,
      password_confirmation: values.confirmPassword
    }

    postResetPassword(postData)
  }

  return [resetPasswordState, doPostResetPassword] as const
}
