import React from 'react'
import {
  ButtonPrimary,
  Heading1,
  Para,
  Stack,
  Padding,
  FlexRow,
  AlertError,
  Form,
  FormFieldPassword,
  Formik
} from '@papertrail/styleguide'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import * as hooks from './hooks'
import { useTitle } from '@papertrail/web3-utils'
import { PASSWORD_REGEX } from '../../consts'

type FormValues = {
  password: string
  confirmPassword: string
}

type Props = {
  locationSearch: string
}

export default function RespondResetPassword(props: Props) {
  const { locationSearch } = props
  useTitle('Reset Password')
  const history = useHistory()
  const params = new URLSearchParams(locationSearch)
  const email = params.get('email')
  const code = params.get('code')

  const validationSchema = yup.object({
    password: yup.string().required('Password is required').matches(PASSWORD_REGEX, 'Password must meet requirements'),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .oneOf([yup.ref('password')], 'Passwords do not match')
  })

  const [resetPasswordState, postResetPassword] = hooks.useResetPasswordApi()
  const resetButtonText = resetPasswordState.isLoading ? 'Please wait...' : 'Reset Password'

  function resetPassword(values: FormValues) {
    postResetPassword({
      email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      code: code
    })
  }

  function redirectToLogin() {
    history.replace('/login')
  }

  function redirectToRetry() {
    history.replace('/login/reset')
  }

  function renderContent() {
    if (!email) {
      return <Padding>Email parameter missing.</Padding>
    } else if (!code) {
      return <Padding>Code parameter missing.</Padding>
    } else if (resetPasswordState.isError && resetPasswordState.error.errorCode === 'reminder_code_is_invalid') {
      return (
        <Stack alignItems="center" width="350px" spacing={1} pt={10}>
          <Heading1>Reset your password</Heading1>
          <Para align="center">Sorry, the password reset token has expired. Please try again.</Para>
          <ButtonPrimary fixedWidth onClick={redirectToRetry}>
            Retry
          </ButtonPrimary>
        </Stack>
      )
    } else if (!resetPasswordState.isLoaded) {
      return (
        <Stack alignSelf="stretch" p={2}>
          <FlexRow justifyContent={'flex-start'}>
            <Link to={'/login'}>Back</Link>
          </FlexRow>
          <Stack alignItems="center">
            <Stack p={2} alignItems="center" width="450px" spacing={3}>
              <Heading1>Reset your password</Heading1>
              <Para align="center">
                Your password must be at least 8 characters and a combination of uppercase and lowercase letters,
                numbers, symbols.
              </Para>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  resetPassword(values)
                }}>
                <Form>
                  <Stack alignItems="center" width="350px">
                    <FormFieldPassword label="New password" placeholder="Enter password" name="password" mandatory />
                    <FormFieldPassword
                      label="Confirm password"
                      placeholder="Confirm password"
                      name="confirmPassword"
                      mandatory
                    />
                    <Stack alignItems="center" width="350px" spacing={1}>
                      <ButtonPrimary fixedWidth disabled={resetPasswordState.isLoading} isSubmit>
                        {resetButtonText}
                      </ButtonPrimary>
                      {resetPasswordState.isError && (
                        <AlertError>
                          Sorry, we could not reset your password. Please try again or contact support.
                        </AlertError>
                      )}
                    </Stack>
                  </Stack>
                </Form>
              </Formik>
            </Stack>
          </Stack>
        </Stack>
      )
    } else if (resetPasswordState.isLoaded) {
      return (
        <Padding>
          <Stack alignItems="center" width="350px" spacing={1} pt={10}>
            <Para>We have successfully reset your password.</Para>
            <ButtonPrimary fixedWidth onClick={redirectToLogin}>
              Login
            </ButtonPrimary>
          </Stack>
        </Padding>
      )
    }
  }

  return renderContent()
}
