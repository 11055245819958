import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { useLoginApi, useSSOProvidersApi } from './hooks'
import { Tracking, useTitle } from '@papertrail/web3-utils'

import {
  ButtonSecondary,
  ButtonPrimary,
  Form,
  FormFieldCheckbox,
  Formik,
  FormFieldText,
  LogoText,
  FormFieldPassword,
  AlertWarning,
  Stack,
  Heading1,
  Para
} from '@papertrail/styleguide'
import SSOProviderList from '../../components/SSOProviderList'
import { PapertrailSession } from '@papertrail/web3-session'
import { Partner } from '@papertrail/types'

export type Props = {
  prevRoute?: string
}

export default function Login(props: Props) {
  const history = useHistory()
  const [loginState, doLogin] = useLoginApi()
  const initialLoginValues = {
    email: '',
    password: '',
    rememberMe: false
  }
  const partner: Partner = PapertrailSession.partner

  useTitle('Login')

  const validationSchema = yup.object({
    email: yup.string().required('Email or username is required'),
    password: yup.string().required('Password is required')
  })

  const [ssoProviders, loadSSOProviders] = useSSOProvidersApi()

  function postLogin(values) {
    doLogin(values.email, values.password, values.rememberMe)
  }

  useEffect(() => {
    loadSSOProviders({})
  }, [])

  useEffect(() => {
    Tracking.trackEvent('login')
    const redirectUrl = sessionStorage.getItem('redirectUrl')
    if (loginState.isLoaded) {
      if (redirectUrl) {
        sessionStorage.clear()
        history.push(redirectUrl)
      } else if (!props.prevRoute.startsWith('/login') && !props.prevRoute.startsWith('/signup')) {
        history.push(props.prevRoute)
      } else {
        history.push('/')
      }
    }
  }, [loginState])

  function navToSignUp() {
    history.push('/signup')
  }

  function isLastAttempt(details) {
    return details.login_attempts > 0 && details.login_attempts === parseInt(details.max_attempts)
  }

  function renderLoginError() {
    if (loginState.isError) {
      if (loginState.error.errorCode == 'login_attempts_exceeded') {
        return (
          <AlertWarning>
            Maximum login attempts ({loginState.error.details.max_attempts}) exceeded. Account locked for{' '}
            {loginState.error.details.lockout_period}.
          </AlertWarning>
        )
      } else if (loginState.error.errorCode == 'invalid_credentials' && isLastAttempt(loginState.error.details)) {
        return (
          <AlertWarning>
            Last attempt. Your account will be locked for {loginState.error.details.lockout_period} if you enter an
            incorrect password again.
          </AlertWarning>
        )
      } else {
        return <AlertWarning>Please check your details and try again</AlertWarning>
      }
    }
  }

  return (
    <Stack paddingTop={6} spacing={6} alignItems={'center'}>
      {!partner.images.publicLogo && <LogoText />}
      {partner.images.publicLogo && (
        <img id="papertrail-logo" width="120px" src={partner.images.publicLogo} alt="logo" />
      )}
      <Heading1>Login to {partner.partnerName ? partner.partnerName : 'Papertrail'}</Heading1>

      {partner.partnerSummary && (
        <div style={{ marginTop: '10px' }}>
          <Para align={'center'}>{partner.partnerSummary}</Para>
        </div>
      )}
      <Formik
        initialValues={initialLoginValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          postLogin(values)
        }}>
        <Form>
          <Stack alignItems={'center'} width={'350px'}>
            <FormFieldText
              name="email"
              label={'Email address or username'}
              data-cy="email"
              placeholder={'Email address or username'}
            />
            <FormFieldPassword name="password" label={'Password'} data-cy="password" placeholder={'Password'} />
            <span>
              Forgot Password? <Link to={'/login/reset'}>Reset here</Link>
            </span>

            <Stack spacing={2} alignItems={'center'}>
              <Stack spacing={1} paddingTop={2} alignItems={'center'}>
                <FormFieldCheckbox
                  noHelpText
                  name="rememberMe"
                  label="Stay logged in for 2 weeks"
                  data-cy="rememberme"
                />
                <ButtonPrimary isSubmit disabled={loginState.isLoading}>
                  {loginState.isLoading ? 'Please wait' : 'Login to ' + partner.partnerName}
                </ButtonPrimary>
                {renderLoginError()}
                <Para align={'center'}>Or</Para>
                {ssoProviders.isLoaded && <SSOProviderList items={ssoProviders.data} />}
              </Stack>

              <Stack spacing={1} paddingTop={1} alignItems={'center'}>
                <Para align={'center'}>Don&apos;t have an account?</Para>
                <ButtonSecondary fixedWidth onClick={navToSignUp}>
                  Create your account
                </ButtonSecondary>
              </Stack>
            </Stack>
          </Stack>
        </Form>
      </Formik>
    </Stack>
  )
}
